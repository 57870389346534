import { EndPoints, endPoints } from '@/config/endpoints'
import static_corhot from '@/config/metadata'

// interface RegisterState {
//   wallet_address: string
// }

interface Response {
  user_role: string
  _id: string
  user_name: string
  email_id: string
  wallet_address: string
  created_at: string
  updated_at: string
  __v: number
}

interface LoginResponse {
  user_id: string
  user_name: string
  whitelist_status: boolean
}

export interface EntityMeta {
  claims_exposure?: number
  project_team?: number
  security_audit?: number
}

export interface RealDataEntity {
  _id: string
  entity: string
  metadata: any
  statusCode?: number
}

export interface EntityResponse {
  data: RealDataEntity[]
}

interface EventResponse {
  _id: string
}

interface PastEventResponse {
  _id: string
}

function getHeaderAppendedToken() {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  // headers.append('auth-token', authToken)
  return headers
}

export const getHealthCheck = async (): Promise<any> => {
  return fetch(`${endPoints.HEALTH_CHECK}`, {
    method: 'GET',
    redirect: 'follow',
  })
    .then((response) => response.json())
    .then((res) => {
      return res
    })
    .catch((error) => console.error('Error', error))
}

export const fetchEntityData = async (): Promise<EntityResponse> => {
  // const body = {
  //     filter: {
  //         entity: entityType
  //     }
  // }
  // const response = await fetch(`${endPoints.ENTITY}`, {
  //     method: "POST",
  //     body: JSON.stringify(body),
  //     redirect: 'follow',
  //     headers: getHeaderAppendedToken(),
  // })
  // const originData = await response.json()
  const res: EntityResponse = { data: [static_corhot] }
  // const res: EntityResponse = await response.json()
  return res
}

export const fetchEventData = async (
  eventType: string,
  authToken?: string,
  skip?: number,
  limit?: number,
  address?: string,
): Promise<EventResponse> => {
  const body =
    eventType === 'all'
      ? {
          filter: {},
        }
      : {
          filter: {
            event_types: eventType,
            staker: address,
          },
          skip,
          limit,
        }

  const response = await fetch(`${endPoints.EVENT}`, {
    method: 'POST',
    body: JSON.stringify(body),
    redirect: 'follow',
    headers: getHeaderAppendedToken(),
  })
  const res: EventResponse = await response.json()

  return res
}

export const fetchPastEventData = async (): Promise<PastEventResponse> => {
  const body = {}
  const response = await fetch(`${endPoints.PAST_EVENT}`, {
    method: 'POST',
    body: JSON.stringify(body),
    redirect: 'follow',
    headers: getHeaderAppendedToken(),
  })
  const res: PastEventResponse = await response.json()
  return res
}

export async function authUser(address: string) {
  const sigResponse = await fetch(`${EndPoints.GET_SIGNATURE(address)}`)

  const sigData = await sigResponse.json()

  const signature = sigData.data.signature.signature

  const authResponse = await fetch(`${EndPoints.AUTH}`, {
    method: 'POST',
    body: JSON.stringify({
      signature,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const authData = await authResponse.json()

  localStorage.setItem('authToken', authData.token)

  return authData.token
}

export const AUTH_TOKEN = `Bearer ${localStorage.getItem('authToken')}`
